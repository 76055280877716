/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import './Cube.css'; // Import CSS file for styling
import { useRecoilState, useRecoilValue } from 'recoil';
import { atom_originalTourState, atom_tourState } from '@app/shared/state/tour.atom';
import { Button } from '@mui/material';
import { atom_filterState, atom_selectedFilterState } from '@app/shared/state/filter.atom';
import { Stop, Tour } from '@app/shared/models/tour.type';
import { useGlobalHooks } from '@app/core/hooks/global-hooks';

const Cube = () => {
	const [activeTour, setTourState] = useRecoilState(atom_tourState);
	const [
		originalTour,
		setOriginalTour,
	] = useRecoilState(atom_originalTourState);
	const [filters, setFilters] = useRecoilState(atom_filterState);
	const [selectedFilter, setSelectedFilter] = useRecoilState(atom_selectedFilterState);
	const [isOpen, setIsOpen] = useState(false);
	const _g = useGlobalHooks();

	if (!selectedFilter) {
		setOriginalTour(activeTour)
	}

	const toggleCube = (e: { stopPropagation: () => void; }) => {
		setIsOpen(!isOpen);
		e.stopPropagation();
	};

	useEffect(() => {

		if (filters.length > 0) {
			return;
		}
		const tags = activeTour?.stops.reduce((acc, nextStop) => {
			nextStop?.tags?.map(tag => !!tag && acc.add(tag))
			return acc
		}, new Set<string>()) as Set<string>;
		if (tags?.size > 0) {
			setFilters([...new Array(...tags)])
		}
	}, [activeTour])

	const handleActiveFilter = (thefilter: string) => {
		if (thefilter === 'RESET') {
			setSelectedFilter('')
			setTourState(originalTour as any)
			return
		}
		setSelectedFilter(thefilter)

		const activeFilter = filters
			.find((filter: string) => filter === thefilter)

		if (!activeFilter) {
			return
		}
		const newTour = { ...originalTour, stops: originalTour?.stops.filter(stop => stop?.tags?.includes(activeFilter as string)) as unknown as Stop[] }

		setTourState(newTour as any)
	}

	return (
		<div>
			{filters.length > 0 && <div style={{height: 60,border: '2px solid white', overflowY: 'hidden', overscrollBehavior: 'contain', overflow: 'auto', background: _g.theme.palette.primary.main }} className={`cube ${isOpen ? 'open' : ''}`} onClick={toggleCube}>
				{isOpen ? ['CLOSE', 'RESET', ...filters].map((filter, index) => {
					if (filter === 'CLOSE') {
						return <i className="fa-solid fa-chevron-left scale-150 mr-4 ml-4" style={{
							background: 'white', padding: '8px', borderRadius: '50%', color: 'black', width: 24, height: 24, display: 'flex', alignItems: 'center', justifyContent: 'center'
						}} onClick={toggleCube} key={'filter' + index}></i>
					}
					return <Button sx={{height:36, background:  selectedFilter === filter ? 'darkseagreen' : 'white', color: 'black' }} onClick={() => handleActiveFilter(filter)} style={{ fontSize: 12, padding: 10 }} key={'filter-button' + index} className="filter m-2">{filter === 'RESET' ? 'All': filter}</Button>
				}) :
					<div style={{
						background: 'white',
						borderRadius: '50%',
						width: 38,
						height: 38,
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center'
					}}>
						<i className="fa-solid fa-filter scale-150"></i>
					</div>}
			</div>}
		</div>
	);
};

export default Cube;