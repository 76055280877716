import { Seperator } from '@app/shared/components/Seperator';
import { Stop } from '@app/shared/models/tour.type';
import { atom_activeStopState } from '@app/shared/state/active-stop.atom';
import { atom_openedStopState } from '@app/shared/state/opened-stop.atom';
import { atom_showModalContent } from '@app/shared/state/show-content-modal.atom';
import { atom_tourState } from '@app/shared/state/tour.atom';
import { atom_visitedLocations } from '@app/shared/state/visited.atom';
import { Box, Button, CircularProgress, Container, Divider, Typography } from '@mui/material';
import { Modal } from 'react-responsive-modal';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPlayer from 'react-player';
import { useRecoilState, useRecoilValue } from 'recoil';
import { RateUsModal } from './RateUsModal';
import { TriviaPage } from './TriviaPage';
import moment from 'moment'
import ClaimStatus from './ClaimStatus';
// eslint-disable-next-line no-relative-import-paths/no-relative-import-paths
import tasteImage from '../../../assets/images/taste2.png'
// eslint-disable-next-line no-relative-import-paths/no-relative-import-paths
import noInternet from '../../../assets/images/no-internet.png'
import pako from 'pako';
import axios from 'axios';
import crypto from 'crypto-js';

type StopDetailsProps = {
	children?: React.ReactNode;
};

export function decompress(gzippedContent: string) {
	const compressedData = Uint8Array.from(atob(gzippedContent), (c) =>
		c.charCodeAt(0)
	);
	const decompressedData = pako.ungzip(compressedData, { to: 'string' });
	return decompressedData;
}

export const StopDetails: React.FC = React.memo<StopDetailsProps>(
	function StopDetails(_p) {
		const { t } = useTranslation();
		const [state_activeStopState] = useRecoilState(atom_activeStopState);
		const tourState = useRecoilValue(atom_tourState);
		const [, setState_openedStopState] = useRecoilState(atom_openedStopState);
		const [isExpired, updateExpiration] = useState(false);
		const [state_showModal, setState_showModal] = useRecoilState(
			atom_showModalContent
		);
		const [state_visitedLocations, setState_visitedLocations] = useRecoilState(
			atom_visitedLocations
		);
		const [showRatingModal, setShowRatingModal] = useState(false);
		const [claimData, setClaimData] = useState({} as { claimedAt: string, amount: number });
		const [isTasteClicked, setIsTasteClicked] = useState(false);
		const [tasteCount, setTasteCount] = useState(0);
		const [isCouponLoading, setIsCouponLoading] = useState(false);

		function renderInternetRequired() {
			return (
				<Box>
					<Typography variant='h1' className='flex justify-center pt-4'>{t('on_tour.stop.connection_lost_title')}</Typography>
					<Typography style={{ wordBreak: 'break-word', textAlign: 'center' }} variant="body2" className='flex justify-center align-center p-2'>{t('on_tour.stop.connection_lost_description')}</Typography>
					<Box className="flex justify-center p-2">
						<img src={noInternet} style={{
							maxWidth: '80%',
							maxHeight: 300
						}} />
					</Box>
					<Box className="flex justify-center pt-4">
						<Button onClick={() => { setIsTasteClicked(false); }}>{t('on_tour.stop.connection_lost_try_again')}</Button>
					</Box>
				</Box>
			)
		}

		function renderCoupon(media: any) {
			return (
				<Box>
					<Typography variant='h1' className='flex justify-center p-2'>{t('on_tour.stop.barcode_reveal_title')}</Typography>
					<Typography className='flex justify-center p-2'>{t('on_tour.stop.barcode_reveal_description')}</Typography>
					<Box className="flex justify-center p-2">
						<img src={media.product_pic} style={{
							maxWidth: '80%',
							maxHeight: 300
						}} />
					</Box>
					<Typography
						className='flex justify-between pt-8 pb-2 pl-4 pr-4'
						variant="h5"
					>
						<span>{media.product_name}</span>
						<span>{Number(tasteCount)}</span>

					</Typography>

					<Divider style={{ color: 'black', background: 'black' }} />
					<Box className="flex justify-center pt-4">
						<Button onClick={async () => {
							// await updateFoodUsage();
							getTasteCount(true)
							setIsTasteClicked(false);
						}}>{t('on_tour.stop.barcode_reveal_button_yes')}</Button>
					</Box>
					<Box className="flex justify-center pt-4">
						<Button
							onClick={() => {
								setIsTasteClicked(false);
							}}
							variant="outlined">{t('on_tour.stop.barcode_reveal_button_no')}</Button>
					</Box>
				</Box>
			)
		}

		async function getTasteCount(reveal = false) {
			setIsCouponLoading(true);
			try {

				let count = 0;
				if (state_activeStopState?.all_media.map((media) => media.type).includes('coupon')) {
					const fingerprint = {
						width: window.screen.width,
						height: window.screen.height,
						language: window.navigator.language,
						platform: window.navigator.platform,
						hardwareConcurrency: window.navigator.hardwareConcurrency
					};
					const { uID, tID, gID } = JSON.parse(localStorage.getItem('credential_items') || '{}');
					const uniqueID = crypto
						.MD5(JSON.stringify(fingerprint))
						.toString(crypto.enc.Base64);
					const data = {
						'gID': gID,
						'tID': tID,
						'uID': uID,
						'uniqueID': uniqueID,
						'stopID': state_activeStopState?.id,
						date: reveal ? new Date().toISOString() : undefined
					}
					await axios.post('https://europe-west3-tours-app-1579553856346.cloudfunctions.net/validateCoupon', data).then(res => {
						if (res.data.expiredTime) {
							const expiredTime = moment(res.data.expiredTime).add(12, 'minutes');
							const currentTime = moment();
							if (expiredTime.isBefore(currentTime)) {
								console.log('expiredTime is before currentTime');
								updateExpiration(true);
							}
							setClaimData({ claimedAt: res.data.expiredTime as string, amount: res.data.available });
						}
						count = res?.data.available || 0;
					}).catch(err => {
						console.log({ err })
						count = -1;
					})
				}
				// TODO - fix when done
				if (count <= 0) {
					setClaimData({ claimedAt: moment().subtract(1, 'day').toISOString(), amount: 0 });
					return updateExpiration(true);
				}
				setTasteCount(count);
			} catch (err) {
				console.log({ err })
			} finally {
				console.log('finally', claimData);
				setIsCouponLoading(false)
			}
		}

		useEffect(() => {
			getTasteCount();
		}
		, [state_activeStopState?.all_media])



		function getHighestStopIndex() {

			const highest = tourState?.stops?.reduce((acc: number, next: Stop) => {
				if (next.stop_index && next.stop_index > acc) {
					return next.stop_index;
				}
				return acc;
			}, -1);
			return highest;
		}

		return (
			<Box className="flex flex-col gap-10">
				{state_activeStopState?.all_media.map((media, i) => {
					if (media.type?.includes('video'))
						return (
							<div className="fragment">
								<Box key={'media' + media.order}>
									<Typography className="p-4 pb-2">{media.title}</Typography>
									<ReactPlayer
										// onError={(err) => alert(JSON.stringify(err))}
										key={'rplayer' + media.order}
										style={{ maxHeight: media?.isVertical ? 'unset' : '30vh' }}
										className={
											media?.isVertical ? 'vertical-config' : 'fragment'
										}
										width="100%"
										url={media.url?.split('&token=')?.[0]}
										controls
									/>
								</Box>
								{i < state_activeStopState?.all_media?.length - 1 && (
									<Seperator />
								)}
							</div>
						);

					if (media.type?.includes('audio'))
						return (
							<div className="fragment">
								<Box key={'raudio' + media.order}>
									<Typography className="p-4 pb-2">{media.title}</Typography>
									<audio
										controls
										className="h-7 mt-1 mb-2"
										style={{ width: '100%' }}
									>
										<source
											src={media.url?.split('&token=')?.[0]}
											type="audio/mpeg"
										/>
										Your browser does not support the audio element.
									</audio>
								</Box>
								{i < state_activeStopState?.all_media?.length - 1 && (
									<Seperator />
								)}
							</div>
						);

					if (media.type?.includes('question'))
						return (
							<div className="fragment">
								<TriviaPage question={media} />
								{i < state_activeStopState?.all_media?.length - 1 && (
									<Seperator />
								)}
							</div>
						);

					if (media.type?.includes('image'))
						return (
							<Container>
								<Typography className="p-4 pb-2">{media.title}</Typography>
								<img width="100%" src={media.url?.split('&token=')?.[0]} />
								{i < state_activeStopState?.all_media?.length - 1 && (
									<Seperator />
								)}
							</Container>

						);

					if (media.type?.includes('text'))
						return (
							<div className="fragment">
								<Box className="p-4 pb-2" key={'rtext' + media.order}>
									<Typography>{media.title}</Typography>
									<Typography
										style={{ wordBreak: 'break-word' }}
										variant="body2"
									>
										{media.content}
									</Typography>
								</Box>
								{i < state_activeStopState?.all_media?.length - 1 && (
									<Seperator />
								)}
							</div>
						);
					if (media.type?.includes('html')) {
						let htmlContent = '';
						try {	
							htmlContent = decompress(media?.content as string);
						} catch (err) {
							htmlContent = media?.content as string;
						}

						return (
							<Box className="p-4 pb-2 ml-2" key={'rhtml' + media.order}>
								<div dangerouslySetInnerHTML={{ __html: htmlContent }}></div>
								{i < state_activeStopState?.all_media?.length - 1 && (
									<Seperator />
								)}
							</Box>

						);
					}
					if (media.type?.includes('coupon')) {

						return (
							isCouponLoading ?
								<Box className="p-4 pb-2 ml-2 flex justify-center items-center" key={'rhtml' + media.order}>
									<CircularProgress />
								</Box>
								:
								<Box className="p-4 pb-2 ml-2" key={'rhtml' + media.order}>
									<div style={{ display: 'flex', justifyContent: 'center' }}>
										<img width="70%" src={tasteImage} />
									</div>
									<Typography className='flex justify-center p-1' variant='h1'>Your Coupon Awaits!</Typography>
									<Divider sx={{ border: '0.05rem solid black', marginBottom: 5 }} />
									<Typography className='flex justify-center p-1' variant='h1'>{media.product_name}</Typography>
									<Typography
										className='flex justify-center p-1'
										style={{ wordBreak: 'break-word' }}
									>
										{media.offer_in_details}
									</Typography>
									<Typography
										className='flex justify-center p-1'
									>
										{`${t('on_tour.stop.hours_range')} ${media.hours_range}`}
										{/* product_pic */}
									</Typography>
									<Typography
										className='flex justify-center p-1'
										variant="body2"
									>
										<img style={{ maxWidth: '80%', maxHeight: 300 }} src={media.product_pic} />
									</Typography>

									<Typography
										className='flex justify-between pt-8 pb-2 pl-4 pr-4'
										variant="h5"
									>
										<span>{media.product_name}</span>
										{tasteCount > 0 && <span>{Number(tasteCount)}</span>}

									</Typography>

									<Divider style={{ color: 'black', background: 'black' }} />


									{isExpired ?
										<Box style={{ background: 'rgba(155, 25, 25, 0.12)' }} className='p-4'>
											<Typography className='flex justify-center p-4' variant='h4'>{t('on_tour.stop.success_taste')}</Typography>
											<Typography className='flex justify-center p-4' variant='h5'>{t('on_tour.stop.expired_description')}</Typography>
										</Box>
										:

										<Box sx={{ background: !claimData?.claimedAt ? 'unset' : isExpired ? 'rgba(155, 25, 25, 0.12)' : 'rgba(28, 155, 25, 0.12)' }} className='flex justify-center p-4'>
											{claimData?.claimedAt
												?
												<Box >
													<Typography className="flex justify-center" variant='h1'>{media.product_barcode}</Typography>
													<Typography className="flex justify-center" variant='h1'>{moment(claimData?.claimedAt).format('DD/MM/YY')}</Typography>
													<ClaimStatus updateExpiration={updateExpiration as any} claimData={
														claimData
													} />
												</Box>

												: <Button onClick={() => {
													setIsTasteClicked(true);
												}}>{t('on_tour.stop.give_me_a_taste')}</Button>
											}
											<Modal styles={{
												modal: {
													width: '90%',
													zIndex: 9999999
												},
												modalContainer: {
													zIndex: 9999999
												}
											}}
											open={isTasteClicked}
											onClose={() => {
												setIsTasteClicked(false);
											}}
											center
											>
												{navigator.onLine ? renderCoupon(media) : renderInternetRequired()}
											</Modal>
										</Box>
									}

									{
										i < state_activeStopState?.all_media?.length - 1 && (
											<Seperator />
										)
									}
								</Box>
						);
					}
				})}
				<div style={{ padding: '5%' }}></div>
				<RateUsModal
					showModal={showRatingModal}
					onClose={() => {
						setShowRatingModal(false);
						setState_openedStopState(undefined);
						setState_showModal(false);
					}}
				/>
				<Box className="flex justify-center">
					<Button
						style={{ width: '80%' }}
						className="mb-5"
						onClick={() => {
							const storage = JSON.parse(localStorage.getItem('visitedStops') || '{}') as any
							const visitedStops = new Set<string>(Object.values(storage).length > 0 ? Object.values(storage) : undefined);
							if (!visitedStops.has((state_activeStopState as any)?.id)) {
								visitedStops.add((state_activeStopState as any)?.id);
								localStorage.setItem('visitedStops', JSON.stringify(({ ...Array.from(visitedStops) })));
							}
							if (state_activeStopState?.stop_index === getHighestStopIndex()) {
								setShowRatingModal(true);
							} else {
								setState_openedStopState(undefined);
								setState_showModal(false);
							}

							setState_visitedLocations({
								...state_visitedLocations,
								...(state_activeStopState?.id && {
									[state_activeStopState?.id]: true
								})
							});
						}}
					>
						{t('on_tour.stop.i_finished_the_stop')}
					</Button>
				</Box>
			</Box >
		);
	}
);
